import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import { makeStyles } from "@material-ui/core/styles"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Button, Grid, Typography } from "@material-ui/core"
import TagDisplay from "../sidebar/tagDisplay"
import ToolCategoryList from "../tools/toolCategoryList"
import clsx from "clsx"
import SEO from "../header/seo"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import {
  FavDispatchContext,
  FavStateContext,
} from "../../context/favoriteContext"
import { navigate } from "@reach/router"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import Hyphenated from "react-hyphen"
import de from "hyphenated-de"

const goBack = () => {
  navigate(-1)
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("md")]: {
      padding: "100px 20px 50px",
    },
  },
  containerSmall: {
    paddingTop: "50px",
  },
  backButton: {
    display: "block",
    color: theme.palette.common.black,
    lineHeight: "48px",
    textDecoration: "none",
    transform: "translate(0, 40px)",
    "& svg": {
      marginBottom: "-6px",
    },
  },
  sidebar: {
    background: "#0028A5",
    borderBottom: "6px solid #DC6027",
    borderRadius: "5px",
    color: theme.palette.common.white,
    margin: "100px 20px 0",
    padding: "30px 30px 30px 30px",
    textAlign: "right",
    [theme.breakpoints.up("md")]: {
      float: "right",
      width: "180px",
      margin: "100px 20px 0 0",
      borderRadius: "5px 0 5px 0",
    },
    "& > div": {
      width: "100%",
      float: "none",
    },
  },
  title: { marginBottom: "1.5rem", color: "#0028A5" },
  subtitle: { marginBottom: "2rem" },
  bodyText: { marginBottom: "2rem" },
  buttonMain: { marginRight: "20px" },
  buttonSecondary: {
    "& svg": {
      marginRight: 5,
    },
  },
  toolAction: {},
}))

const ToolPage = props => {
  const { data, intl } = props
  const classes = useStyles()
  const dispatch = useContext(FavDispatchContext)
  const state = useContext(FavStateContext)

  const post = data.markdownRemark
  const posts = data.allMarkdownRemark.group.filter(function (group) {
    return post && post.frontmatter.category.includes(group.fieldValue)
  })
  const cats = post.frontmatter.category.map(category => {
    const cat = data.cats.edges.find(
      e => e.node.frontmatter.slug === `${category}`
    )
    return (
      <Link to={`/categories/${category}`}>{cat.node.frontmatter.title}</Link>
    )
  })

  const addToFav = () => {
    dispatch({ type: "addFav", payload: post.fields.slug })
  }

  const removeFromFav = () => {
    dispatch({ type: "removeFav", payload: post.fields.slug })
  }

  if (post !== null) {
    return (
      <Layout>
        <SEO title={post.frontmatter.title} />
        <Link to={"#"} onClick={goBack} className={classes.backButton}>
          <Typography variant="h4">
            <ArrowLeftIcon /> Back
          </Typography>
        </Link>
        <Grid container>
          <Grid item xs={12} md={9} className={classes.container}>
            <Typography variant="h1" className={classes.title}>
              {post.frontmatter.title}
            </Typography>
            <Typography variant="h3" className={classes.subtitle}>
              {post.frontmatter.subtitle}
            </Typography>
            <Hyphenated language={intl.local}>
              <Typography
                className={classes.bodyText}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </Hyphenated>
            {post.frontmatter.access && (
              <Hyphenated language={intl.local}>
                <Typography variant="h4">
                  {intl.formatMessage({ id: `get-access` })}
                </Typography>
                <Typography
                  className={classes.bodyText}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.access }}
                />
              </Hyphenated>
            )}
            {post.frontmatter.dataprivacy && (
              <Hyphenated language={intl.local}>
                <Typography variant="h4">
                  {intl.formatMessage({ id: `check-data-privacy` })}
                </Typography>
                <Typography
                  className={classes.bodyText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.dataprivacy,
                  }}
                />
              </Hyphenated>
            )}
            {post.frontmatter.documentation && (
              <Hyphenated language={intl.local}>
                <Typography variant="h4">
                  {intl.formatMessage({ id: `find-documentation` })}
                </Typography>
                <Typography
                  className={classes.bodyText}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.documentation,
                  }}
                />
              </Hyphenated>
            )}
            <div className={clsx(classes.toolAction)}>
              <Button
                variant="contained"
                className={clsx(classes.buttonMain)}
                color="secondary"
                href={post.frontmatter.link}
              >
                {intl.formatMessage({ id: "go-to-link" })}
              </Button>

              {state.favorites.includes(post.fields.slug) ? (
                <Button
                  variant="outlined"
                  className={clsx(classes.buttonSecondary)}
                  color="secondary"
                  onClick={removeFromFav}
                >
                  <FavoriteIcon /> {intl.formatMessage({ id: "remove-fav" })}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={clsx(classes.buttonSecondary)}
                  color="secondary"
                  onClick={addToFav}
                >
                  <FavoriteBorderIcon />{" "}
                  {intl.formatMessage({ id: "add-to-fav" })}
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.sidebar}>
              <TagDisplay title="costs" content={post.frontmatter.costs} />
              <TagDisplay
                title="licence"
                content={intl.formatMessage({
                  id: `${post.frontmatter.licence}`,
                })}
              />
              <TagDisplay title="category" content={cats} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.container}>
              {posts.map(group => {
                const category = data.cats.edges.find(
                  e => e.node.frontmatter.slug === group.fieldValue
                )
                return (
                  <ToolCategoryList
                    title={category.node.frontmatter.title}
                    key={group.fieldValue}
                    edges={group.edges}
                    category={group.fieldValue}
                  />
                )
              })}
            </div>
          </Grid>
        </Grid>
      </Layout>
    )
  } else {
    return <div>this page does not exist</div>
  }
}

export default injectIntl(ToolPage)

export const query = graphql`
  query($slug: String!, $language: String) {
    markdownRemark(fields: { slug: { eq: $slug }, lang: { eq: $language } }) {
      html
      frontmatter {
        title
        subtitle
        costs
        licence
        link
        category
        access
        dataprivacy
        documentation
      }
      fields {
        slug
        lang
      }
    }
    allMarkdownRemark(filter: { fields: { lang: { eq: $language } } }) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              costs
              licence
              link
            }
            fields {
              slug
              lang
            }
          }
        }
      }
    }
    cats: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/categories/" }, lang: { eq: $language } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`
